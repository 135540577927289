import * as React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"

import * as sty from './thankYou.module.scss';

const ThankYou = (lang) => (
  <Container>
    <div className={sty.thankyou}>
      <h1>Thank You for contacting us!</h1>
      <p>We will get back to you within 72 hours.</p>
        <PrismicLink href="/contact" className="btn_purple">
          Return to Contact
        </PrismicLink>
    </div>
  </Container>
)

export default withPrismicUnpublishedPreview(ThankYou)
